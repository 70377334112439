import { HHTabData, HHTabs } from '@hinge-health/react-component-library';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useGenericMixpanelTrack } from '../../../../../hooks/use-generic-mixpanel';
import { MIXPANEL_EVENTS } from '../../../../../lib/mixpanel/mixpanel-event-names';
import { DashboardTabSelectionType } from '../../../../../lib/mixpanel/types/generic-mixpanel-event-types';
import { PtDashboardFilter } from '../../../constants/pt-dashboard';
import { PT_VIDEO_VISIT_TAB_NAMES } from '../../../constants/strings/pt-layout';
import { selectAllPTWorkflowAtom, selectedPtDashboardFilters, videoTabchanged } from '../../../jotai/atoms';
import { PtDashboardFilters } from '../../../types/pt';
export interface VideoVisitTabsProps {
  tableContent: JSX.Element;
}
export const VideoVisitTabs: React.FC<VideoVisitTabsProps> = ({
  tableContent
}) => {
  const [selectedDashboardFilter, setSelectedDashboardFilter] = useAtom(selectedPtDashboardFilters);
  const [, setAllPTWorkflowFlag] = useAtom(selectAllPTWorkflowAtom);
  const [tabIndex, setTabIndex] = useState(0);
  const mixPanelTrack = useGenericMixpanelTrack();
  const invokeMixpanelTrack = (event: string, selectedDashboardFilter_0: string): void => {
    mixPanelTrack<DashboardTabSelectionType>(event, {
      selectedTabName: selectedDashboardFilter_0
    });
  };
  const [, setvideoTabchangedChanged] = useAtom(videoTabchanged);
  useEffect(() => {
    if (selectedDashboardFilter.filterTabName === PT_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS) {
      setTabIndex(0);
      invokeMixpanelTrack(MIXPANEL_EVENTS.VIDEO_VISITS_UPCOMING_VIDEO_VISITS_TAB_SELECTED, PT_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS);
    } else {
      setTabIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDashboardFilter]);
  const handleTabChange = (event_0: number): void => {
    if (event_0 === 0) {
      setSelectedDashboardFilter(PtDashboardFilter[PtDashboardFilters.VideoVisitUpcoming]);
    }
    if (event_0 === 1) {
      setSelectedDashboardFilter(PtDashboardFilter[PtDashboardFilters.VideoVisitIncomplete]);
      invokeMixpanelTrack(MIXPANEL_EVENTS.VIDEO_VISITS_POST_VIDEO_VISIT_TASKS_TAB_SELECTED, PT_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS);
    }
    setvideoTabchangedChanged(true);
    setAllPTWorkflowFlag(false);
  };
  const defaultTabData: HHTabData[] = [{
    tabContent: tableContent,
    tabLabel: PT_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS,
    key: PT_VIDEO_VISIT_TAB_NAMES.UPCOMING_VIDEO_VISITS
  }, {
    tabContent: tableContent,
    tabLabel: PT_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS,
    key: PT_VIDEO_VISIT_TAB_NAMES.INCOMPLETE_VIDEO_VISITS
  }];
  return <HHTabs layoutStyles={{
    margin: '2px'
  }} tabsData={defaultTabData} onTabChange={handleTabChange} defaultTabIndex={tabIndex} />;
};