import { c as _c } from "react-compiler-runtime";
import { useClient } from '@splitsoftware/splitio-react';
import { useMemo } from 'react';
import { OsWorkflowTypes, PtWorkflowTypes, videoVisitWorkflowTypes } from '../../../custom-types';
import { ON, SPLIT_TREATMENTS } from '../constants/splits';
import { useShellContext } from '../contexts/shell.context';
import { DashboardType, healthLogCoachWorkflows, healthLogCoachWorkflowsWithoutPainUptick, mediumTierRetentionWorkflows } from '../custom-types';
import { CoachDashboardFilters, CoachWorkflowTypes, commonExcludedStates, defaultCoachWorkflows } from '../types/coach';
import { HouseCallState } from '../types/house-calls';
import { defaultOsWorkflows, OsDashboardFilters } from '../types/os';
import { commonPtExcludedStates, defaultPtWorkflows, PtDashboardFilters } from '../types/pt';
import { VideoVisitsState } from '../types/video-visit';
import { CoachWorkflowFilter, OsWorkflowFilter, PtWorkflowFilter } from '../types/workflow-filter';
type SplitFilteredWorkflowTypes<T extends CoachDashboardFilters | PtDashboardFilters | OsDashboardFilters> = Record<T, T extends CoachDashboardFilters ? CoachWorkflowFilter : T extends PtDashboardFilters ? PtWorkflowFilter : OsWorkflowFilter>;
type FilterStates<T extends DashboardType> = Record<T extends DashboardType.Coach ? CoachDashboardFilters : T extends DashboardType.PhysicalTherapist ? PtDashboardFilters : OsDashboardFilters, T extends DashboardType.Coach ? CoachWorkflowFilter : T extends DashboardType.PhysicalTherapist ? PtWorkflowFilter : OsWorkflowFilter>;
const useSplitTreatmentValue = treatment => {
  const splitClient = useClient();
  const {
    adminProfile
  } = useShellContext();
  const isSplitTreatmentOn = splitClient?.getTreatment(treatment, {
    adminUuid: adminProfile?.uuid || "*"
  }) === ON;
  return isSplitTreatmentOn;
};
export const useFilterStates = dashboardType => {
  const $ = _c(23);
  const healthLogPainUptickSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.HEALTH_LOG_PAIN_UPTICKS);
  const mediumTierSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.MEDIUM_TIER_RETENTION_WORKFLOWS);
  const encountersTreatmentOn = useSplitTreatmentValue(SPLIT_TREATMENTS.ENCOUNTERS);
  const reEngagerTreatmentOn = useSplitTreatmentValue(SPLIT_TREATMENTS.REENGAGER_WORKFLOW_MIGRATION);
  const firstWeekEnsoCheckInTreatmentOn = useSplitTreatmentValue(SPLIT_TREATMENTS.FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION);
  const weeklyEnsoReviewTreatmentOn = useSplitTreatmentValue(SPLIT_TREATMENTS.WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION);
  const painUptickWorkflowsToShowOnPTDashboard = useSplitTreatmentValue(SPLIT_TREATMENTS.PAIN_UPTICK_WORKFLOWS_ASSIGNED_TO_PT);
  const surgeryTrendSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.SURGERY_TREND_WORKFLOW_MIGRATION);
  const careCoordinationWorkflowDashboardSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT);
  const isAqRedFlagsWorkflowSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.AQ_RED_FLAGS);
  const outreachNeededTreatment = useSplitTreatmentValue(SPLIT_TREATMENTS.OUTREACH_NEEDED_WORKFLOW_MIGRATION);
  const ptMemberAlertRemindersTreatment = useSplitTreatmentValue(SPLIT_TREATMENTS.PT_MEMBER_ALERTS_REMINDERS);
  const isPtActionPlanReviewWorkflowSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW);
  const isSetupActionPlanWorkflowSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW);
  const isCoachWeeklyGoalExpirySplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY);
  const isIndicationUpdateSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.INDICATION_UPDATE_WORKFLOW);
  const isCoachMemberUpdatedWeeklyGoalSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL);
  const isCoachVideoVisitSplitOn = useSplitTreatmentValue(SPLIT_TREATMENTS.COACH_VIDEO_VISIT);
  let t0;
  if (dashboardType === DashboardType.Coach) {
    let t1;
    if ($[0] !== healthLogPainUptickSplitOn || $[1] !== painUptickWorkflowsToShowOnPTDashboard || $[2] !== mediumTierSplitOn || $[3] !== encountersTreatmentOn || $[4] !== reEngagerTreatmentOn || $[5] !== careCoordinationWorkflowDashboardSplitOn || $[6] !== weeklyEnsoReviewTreatmentOn || $[7] !== outreachNeededTreatment || $[8] !== isCoachWeeklyGoalExpirySplitOn || $[9] !== isCoachMemberUpdatedWeeklyGoalSplitOn || $[10] !== isCoachVideoVisitSplitOn) {
      let coachWorkflowsServedOn = [...defaultCoachWorkflows];
      if (healthLogPainUptickSplitOn && !painUptickWorkflowsToShowOnPTDashboard) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, ...healthLogCoachWorkflows];
      }
      if (mediumTierSplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, ...mediumTierRetentionWorkflows];
      }
      if (encountersTreatmentOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachCall];
      }
      if (reEngagerTreatmentOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.ReEngager];
      }
      if (careCoordinationWorkflowDashboardSplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachEscalation];
      }
      if (weeklyEnsoReviewTreatmentOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.WeeklyEnsoReview];
      }
      if (outreachNeededTreatment) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.OutreachNeeded];
      }
      if (isCoachWeeklyGoalExpirySplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachWeeklyGoalExpiry];
      }
      if (isCoachMemberUpdatedWeeklyGoalSplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal];
      }
      if (healthLogPainUptickSplitOn && painUptickWorkflowsToShowOnPTDashboard) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, ...healthLogCoachWorkflowsWithoutPainUptick];
      }
      if (isCoachVideoVisitSplitOn) {
        coachWorkflowsServedOn = [...coachWorkflowsServedOn, CoachWorkflowTypes.CoachVideoVisit];
      }
      t1 = getFilterStatesForCoach(coachWorkflowsServedOn);
      $[0] = healthLogPainUptickSplitOn;
      $[1] = painUptickWorkflowsToShowOnPTDashboard;
      $[2] = mediumTierSplitOn;
      $[3] = encountersTreatmentOn;
      $[4] = reEngagerTreatmentOn;
      $[5] = careCoordinationWorkflowDashboardSplitOn;
      $[6] = weeklyEnsoReviewTreatmentOn;
      $[7] = outreachNeededTreatment;
      $[8] = isCoachWeeklyGoalExpirySplitOn;
      $[9] = isCoachMemberUpdatedWeeklyGoalSplitOn;
      $[10] = isCoachVideoVisitSplitOn;
      $[11] = t1;
    } else {
      t1 = $[11];
    }
    const filterStates = t1;
    t0 = (filterStates as FilterStates<T>);
  } else {
    if (dashboardType === DashboardType.PhysicalTherapist) {
      let t1;
      if ($[12] !== surgeryTrendSplitOn || $[13] !== ptMemberAlertRemindersTreatment || $[14] !== careCoordinationWorkflowDashboardSplitOn || $[15] !== painUptickWorkflowsToShowOnPTDashboard || $[16] !== firstWeekEnsoCheckInTreatmentOn || $[17] !== isAqRedFlagsWorkflowSplitOn || $[18] !== isPtActionPlanReviewWorkflowSplitOn || $[19] !== isSetupActionPlanWorkflowSplitOn || $[20] !== isIndicationUpdateSplitOn) {
        let ptWorkflowsServedOn = [...defaultPtWorkflows];
        const painUptickWorkflowsPt = [PtWorkflowTypes.PainUptick];
        if (surgeryTrendSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.SurgeryTrend];
        }
        if (ptMemberAlertRemindersTreatment) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.PtMemberAlertReminder];
        }
        if (careCoordinationWorkflowDashboardSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.EtModification, PtWorkflowTypes.ClinicalEscalation, PtWorkflowTypes.MedicalEmergencyFollowUp];
        }
        if (painUptickWorkflowsToShowOnPTDashboard) {
          painUptickWorkflowsPt.push(PtWorkflowTypes.MemberPainUptick);
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.MemberPainUptick];
        }
        if (firstWeekEnsoCheckInTreatmentOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.FirstWeekEnsoCheckIn];
        }
        if (isAqRedFlagsWorkflowSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.AqRedFlags];
        }
        if (isPtActionPlanReviewWorkflowSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.ActionPlanReview];
        }
        if (isSetupActionPlanWorkflowSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.SetupActionPlan];
        }
        if (isIndicationUpdateSplitOn) {
          ptWorkflowsServedOn = [...ptWorkflowsServedOn, PtWorkflowTypes.IndicationUpdate];
        }
        t1 = getFilterStatesForPt(ptWorkflowsServedOn, painUptickWorkflowsPt);
        $[12] = surgeryTrendSplitOn;
        $[13] = ptMemberAlertRemindersTreatment;
        $[14] = careCoordinationWorkflowDashboardSplitOn;
        $[15] = painUptickWorkflowsToShowOnPTDashboard;
        $[16] = firstWeekEnsoCheckInTreatmentOn;
        $[17] = isAqRedFlagsWorkflowSplitOn;
        $[18] = isPtActionPlanReviewWorkflowSplitOn;
        $[19] = isSetupActionPlanWorkflowSplitOn;
        $[20] = isIndicationUpdateSplitOn;
        $[21] = t1;
      } else {
        t1 = $[21];
      }
      const filterStates_0 = t1;
      t0 = (filterStates_0 as FilterStates<T>);
    } else {
      let t1;
      if ($[22] === Symbol.for("react.memo_cache_sentinel")) {
        const osWorkflowsServedOn = [...defaultOsWorkflows];
        t1 = getFilterStatesForOs(osWorkflowsServedOn);
        $[22] = t1;
      } else {
        t1 = $[22];
      }
      const filterStates_1 = t1;
      t0 = (filterStates_1 as FilterStates<T>);
    }
  }
  return t0;
};
export const getFilterStatesForCoach = (coachWorkflowsServedOn: CoachWorkflowTypes[]): SplitFilteredWorkflowTypes<CoachDashboardFilters> => ({
  [CoachDashboardFilters.All]: {
    workflowTypes: coachWorkflowsServedOn,
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.UnreadMessages]: {
    workflowTypes: [CoachWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.Completed]: {
    workflowTypes: coachWorkflowsServedOn
  },
  [CoachDashboardFilters.MemberHealthLog]: {
    workflowTypes: [CoachWorkflowTypes.MemberHealthLog],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.ReEngager]: {
    workflowTypes: [CoachWorkflowTypes.ReEngager],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.MemberHealthLogNoResponse]: {
    workflowTypes: [CoachWorkflowTypes.MemberHealthLog],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.UnreadMessagesNoResponse]: {
    workflowTypes: [CoachWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.ActionPlanCheckIn]: {
    workflowTypes: [CoachWorkflowTypes.ActionPlanCheckIn],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.FirstWeekCheckIn]: {
    workflowTypes: [CoachWorkflowTypes.FirstWeekCheckIn],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachCalls]: {
    workflowTypes: [CoachWorkflowTypes.CoachCall],
    excludeStates: [...commonExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [CoachDashboardFilters.CoachCallsPostCallTasks]: {
    workflowTypes: [CoachWorkflowTypes.CoachCall],
    excludeStates: [...commonExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [CoachDashboardFilters.CoachCallsUpcomingCoachCalls]: {
    workflowTypes: [CoachWorkflowTypes.CoachCall],
    excludeStates: [...commonExcludedStates, VideoVisitsState.PerformVideoVisitFollowup]
  },
  [CoachDashboardFilters.CoachEscalation]: {
    workflowTypes: [CoachWorkflowTypes.CoachEscalation],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.WeeklyEnsoReview]: {
    workflowTypes: [CoachWorkflowTypes.WeeklyEnsoReview],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.OutreachNeeded]: {
    workflowTypes: [CoachWorkflowTypes.OutreachNeeded],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.PregnancyStatusChange]: {
    workflowTypes: [CoachWorkflowTypes.PregnancyStatusChange],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.MaternityMilestones]: {
    workflowTypes: [CoachWorkflowTypes.MaternityMilestones],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.AlertReminder]: {
    workflowTypes: [CoachWorkflowTypes.MemberAlertReminder],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.ReviewAndRecalibrate]: {
    workflowTypes: [CoachWorkflowTypes.ActionPlanReview],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.PainUptick]: {
    workflowTypes: [CoachWorkflowTypes.MemberPainUptick],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.BalanceAssessment]: {
    workflowTypes: [CoachWorkflowTypes.BalanceAssessment],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.FirstWeekReview]: {
    workflowTypes: [CoachWorkflowTypes.FirstWeekGoal],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachWeeklyGoalExpiry]: {
    workflowTypes: [CoachWorkflowTypes.CoachWeeklyGoalExpiry],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachMemberUpdatedWeeklyGoal]: {
    workflowTypes: [CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal],
    excludeStates: commonExcludedStates
  },
  [CoachDashboardFilters.CoachVideoVisit]: {
    workflowTypes: [CoachWorkflowTypes.CoachVideoVisit],
    excludeStates: [...commonExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  },
  [CoachDashboardFilters.CoachVideoVisitIncomplete]: {
    workflowTypes: [CoachWorkflowTypes.CoachVideoVisit],
    excludeStates: [...commonExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [CoachDashboardFilters.CoachVideoVisitUpcoming]: {
    workflowTypes: [CoachWorkflowTypes.CoachVideoVisit],
    excludeStates: [...commonExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  }
});
export const getFilterStatesForPt = (ptWorkflowsServedOn: PtWorkflowTypes[], painUptickWorkflowsPt: PtWorkflowTypes[]): SplitFilteredWorkflowTypes<PtDashboardFilters> => ({
  [PtDashboardFilters.All]: {
    workflowTypes: ptWorkflowsServedOn,
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.UnreadMessage]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.ModularIamUnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.UnreadMessageNoResponse]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.VideoVisit]: {
    workflowTypes: videoVisitWorkflowTypes,
    excludeStates: [...commonPtExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  },
  [PtDashboardFilters.VideoVisitIncomplete]: {
    workflowTypes: videoVisitWorkflowTypes,
    excludeStates: [...commonPtExcludedStates, VideoVisitsState.CompleteVideoVisit]
  },
  [PtDashboardFilters.VideoVisitUpcoming]: {
    workflowTypes: videoVisitWorkflowTypes,
    excludeStates: [...commonPtExcludedStates, VideoVisitsState.PerformVideoVisitFollowup, VideoVisitsState.PerformFollowUpVideoVisitFollowup]
  },
  [PtDashboardFilters.HealthLogNotes]: {
    workflowTypes: [PtWorkflowTypes.HealthLog],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.HealthLogNoResponse]: {
    workflowTypes: [PtWorkflowTypes.HealthLog],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.PainUptick]: {
    workflowTypes: painUptickWorkflowsPt,
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ExpiringCarePlans]: {
    workflowTypes: [PtWorkflowTypes.Expiring],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.DischargeAndClosePlan]: {
    workflowTypes: [PtWorkflowTypes.Closing],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.HouseCall]: {
    workflowTypes: [PtWorkflowTypes.HouseCall],
    excludeStates: [...commonPtExcludedStates, HouseCallState.PerformHouseCall, HouseCallState.PostHouseCallFollowup]
  },
  [PtDashboardFilters.HouseCallIncomplete]: {
    workflowTypes: [PtWorkflowTypes.HouseCall],
    excludeStates: [...commonPtExcludedStates, HouseCallState.PrevisitHouseCall]
  },
  [PtDashboardFilters.HouseCallUpcoming]: {
    workflowTypes: [PtWorkflowTypes.HouseCall],
    excludeStates: [...commonPtExcludedStates, HouseCallState.PerformHouseCall, HouseCallState.PostHouseCallFollowup]
  },
  [PtDashboardFilters.Completed]: {
    workflowTypes: [...Object.values(PtWorkflowTypes)]
  },
  [PtDashboardFilters.SurgeryTrend]: {
    workflowTypes: [PtWorkflowTypes.SurgeryTrend],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.EtModification]: {
    workflowTypes: [PtWorkflowTypes.EtModification],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.IndicationUpdate]: {
    workflowTypes: [PtWorkflowTypes.IndicationUpdate],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ClinicalEscalation]: {
    workflowTypes: [PtWorkflowTypes.ClinicalEscalation],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.MedicalEmergencyFollowUp]: {
    workflowTypes: [PtWorkflowTypes.MedicalEmergencyFollowUp],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.FirstWeekEnsoCheckIn]: {
    workflowTypes: [PtWorkflowTypes.FirstWeekEnsoCheckIn],
    excludeStates: commonExcludedStates
  },
  [PtDashboardFilters.AqRedFlags]: {
    workflowTypes: [PtWorkflowTypes.AqRedFlags],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.PtMemberAlertReminder]: {
    workflowTypes: [PtWorkflowTypes.PtMemberAlertReminder],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ActionPlanReview]: {
    workflowTypes: [PtWorkflowTypes.ActionPlanReview],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.SetupActionPlan]: {
    workflowTypes: [PtWorkflowTypes.SetupActionPlan],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.Messages]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.ModularIamUnreadMessages]: {
    workflowTypes: [PtWorkflowTypes.ModularIamUnreadMessages],
    excludeStates: commonPtExcludedStates
  },
  [PtDashboardFilters.AllTypes]: {
    workflowTypes: [PtWorkflowTypes.UnreadMessages, PtWorkflowTypes.ModularIamUnreadMessages],
    excludeStates: commonPtExcludedStates
  }
});
export const getFilterStatesForOs = (oSWorkflowsServedOn: OsWorkflowTypes[]): SplitFilteredWorkflowTypes<OsDashboardFilters> => ({
  [OsDashboardFilters.All]: {
    workflowTypes: oSWorkflowsServedOn,
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.UnreadMessage]: {
    workflowTypes: [OsWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.AlertReminder]: {
    workflowTypes: [OsWorkflowTypes.MemberAlertReminder],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.ReEngager]: {
    workflowTypes: [OsWorkflowTypes.ReEngager],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.OutreachNeeded]: {
    workflowTypes: [OsWorkflowTypes.OutreachNeeded],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.Nuto]: {
    workflowTypes: [OsWorkflowTypes.Nuto],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.Ruto]: {
    workflowTypes: [OsWorkflowTypes.Ruto],
    excludeStates: commonExcludedStates
  },
  [OsDashboardFilters.Completed]: {
    workflowTypes: oSWorkflowsServedOn
  },
  [OsDashboardFilters.UnreadMessageNoResponse]: {
    workflowTypes: [OsWorkflowTypes.UnreadMessages],
    excludeStates: commonExcludedStates
  }
});