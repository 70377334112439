import { NAV_SPLIT_TREATMENTS } from '../../../../../../constants/strings/split';
export const SPLIT_TREATMENTS = {
  COMMUNICATION_PANEL_IAM: 'communication-panel-iam',
  COMMUNICATION_PANEL_SMS: 'communication-panel-sms',
  INTERACTIONS_WORKFLOWS: 'interactions-workflows',
  IAM_SCHEDULED_MESSAGES: 'iam-scheduled-messages',
  IAM_EDIT_DELETE_SENT_MESSAGES: 'iam-edit-delete-sent-messages',
  CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT: NAV_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT,
  IAM_CARE_TEAM_MESSAGING: 'iam-care-team-messaging',
  IAM_CARE_TEAM_MESSAGING_M1: 'iam-care-team-messaging-m1',
  PAIN_UPTICK_WORKFLOWS_ASSIGNED_TO_PT: 'pain-upticks-assigned-to-pt',
  CTT_DEPRECATION_VIDEO_VISIT: 'ctt-deprecation-video-visit',
  MODULAR_IAM_UNREAD_MESSAGE: 'modular-iam-unread-message',
  DISABLE_PHI_MASKING: 'disable-pii-masking',
  ENABLE_OFFSHORE_ACCESS: 'enable_offshore_access_based_on_member_data',
  COACH_WEEKLY_GOAL_EXPIRY: 'coach-weekly-goal-expiry-workflow',
  CONVERSATIONS_IN_COMMS_PANEL: 'conversation-tab-in-comms-panel',
  MODULAR_IAM_PAIN_INSIGHTS: 'modular-iam-pain-insights'
};