import { c as _c } from "react-compiler-runtime";
import { useHingeHealthSecurityContext } from '@hinge-health/react-component-library';
import { useClient } from '@splitsoftware/splitio-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { NAV_SPLIT_TREATMENTS, ON } from '../constants/strings/split';
import { baseCoachQueueWorkflowTypes, coachQueueHealthLogkWorkflowTypes, coachQueueHealthLogPainUptickWorkflowTypes, coachQueueMediumTierRetentionWorkflowTypes, CoachWorkflowTypes, OsWorkflowTypes, PtWorkflowTypes, WorkflowTypes } from '../custom-types';
import { SPLIT_TREATMENTS } from '../modules/dashboard/constants/splits';
import { defaultPtWorkflows } from '../modules/dashboard/types/pt';
import { OktaRole } from '../types/okta-roles';
import { useCurrentStoredAdminHook } from './use-current-stored-admin';
const getSplitTreatmentClient = (splitClient: SplitIO.IBrowserClient | null, adminUuid: string | undefined) => (treatment: string): boolean => {
  const splitTreatmentOn = splitClient?.getTreatment(treatment, {
    adminUuid: adminUuid || '*'
  }) === ON;
  return splitTreatmentOn;
};
export const useGetQueueWorkflowTypes = () => {
  const $ = _c(33);
  const splitClient = useClient();
  const {
    currentStoredAdmin
  } = useCurrentStoredAdminHook();
  let t0;
  if ($[0] === Symbol.for("react.memo_cache_sentinel")) {
    t0 = [];
    $[0] = t0;
  } else {
    t0 = $[0];
  }
  const [workflowTypesToCount, setWorkflowTypesToCount] = useState(t0);
  const getSplitTreatmentOn = getSplitTreatmentClient(splitClient, currentStoredAdmin?.uuid);
  const {
    hingeHealthAuthState
  } = useHingeHealthSecurityContext();
  const healthLogPainUptickSplitOn = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.HEALTH_LOG_PAIN_UPTICKS);
  const reEngagerTreatment = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.REENGAGER_WORKFLOW_MIGRATION);
  const weeklyEnsoReviewTreatment = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.WEEKLY_ENSO_REVIEW_WORKFLOW_MIGRATION);
  const careCoordinationWorkflowTreatment = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT);
  const mediumTierRetentionWorkflowSplitOn = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.MEDIUM_TIER_RETENTION_WORKFLOWS);
  const coachCallsSplitOn = getSplitTreatmentOn(NAV_SPLIT_TREATMENTS.COACH_CALLS);
  const surgeryTrendSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.SURGERY_TREND_WORKFLOW_MIGRATION);
  const careCoordinationWorkflowDashboardSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.CARE_COORDINATION_WORKFLOW_PANEL_TREATMENT);
  const painUptickWorkflowsToShowOnPTDashboard = getSplitTreatmentOn(SPLIT_TREATMENTS.PAIN_UPTICK_WORKFLOWS_ASSIGNED_TO_PT);
  const outreachNeededWorkflowTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.OUTREACH_NEEDED_WORKFLOW_MIGRATION);
  const aqRedFlagsSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.AQ_RED_FLAGS);
  const ptActionPlanReviewWorkflowSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.PT_ACTION_PLAN_REVIEW);
  const firstWkEnsoCheckInSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.FIRST_WEEK_ENSO_CHECK_IN_WORKFLOW_MIGRATION);
  const ptMemberAlertReminderSplitOn = getSplitTreatmentOn(SPLIT_TREATMENTS.PT_MEMBER_ALERTS_REMINDERS);
  const setupActionPlanTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.SETUP_ACTION_PLAN_WORKFLOW);
  const coachWeeklyGoalExpiryTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_WEEKLY_GOAL_EXPIRY);
  const coachMemberUpdatedWeeklyGoalTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_MEMBER_UPDATED_WEEKLY_GOAL);
  const indicationUpdateTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.INDICATION_UPDATE_WORKFLOW);
  const coachVideoVisitTreatment = getSplitTreatmentOn(SPLIT_TREATMENTS.COACH_VIDEO_VISIT);
  let t1;
  if ($[1] !== painUptickWorkflowsToShowOnPTDashboard || $[2] !== healthLogPainUptickSplitOn || $[3] !== outreachNeededWorkflowTreatment || $[4] !== reEngagerTreatment || $[5] !== weeklyEnsoReviewTreatment || $[6] !== careCoordinationWorkflowTreatment || $[7] !== mediumTierRetentionWorkflowSplitOn || $[8] !== coachCallsSplitOn || $[9] !== coachWeeklyGoalExpiryTreatment || $[10] !== coachMemberUpdatedWeeklyGoalTreatment || $[11] !== coachVideoVisitTreatment) {
    t1 = () => {
      let coachWorkflowTypesList = [...baseCoachQueueWorkflowTypes];
      const includePainUptickWorkflowType = painUptickWorkflowsToShowOnPTDashboard ? [...coachQueueHealthLogkWorkflowTypes] : [...coachQueueHealthLogPainUptickWorkflowTypes];
      if (healthLogPainUptickSplitOn) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, ...includePainUptickWorkflowType];
      }
      if (outreachNeededWorkflowTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.OutreachNeeded];
      }
      if (reEngagerTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.ReEngager];
      }
      if (weeklyEnsoReviewTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.WeeklyEnsoReview];
      }
      if (careCoordinationWorkflowTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachEscalation];
      }
      if (mediumTierRetentionWorkflowSplitOn) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, ...coachQueueMediumTierRetentionWorkflowTypes];
      }
      if (coachCallsSplitOn) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachCall];
      }
      if (coachWeeklyGoalExpiryTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachWeeklyGoalExpiry];
      }
      if (coachMemberUpdatedWeeklyGoalTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachMemberUpdatedWeeklyGoal];
      }
      if (coachVideoVisitTreatment) {
        coachWorkflowTypesList = [...coachWorkflowTypesList, CoachWorkflowTypes.CoachVideoVisit];
      }
      setWorkflowTypesToCount((coachWorkflowTypesList as T[]));
    };
    $[1] = painUptickWorkflowsToShowOnPTDashboard;
    $[2] = healthLogPainUptickSplitOn;
    $[3] = outreachNeededWorkflowTreatment;
    $[4] = reEngagerTreatment;
    $[5] = weeklyEnsoReviewTreatment;
    $[6] = careCoordinationWorkflowTreatment;
    $[7] = mediumTierRetentionWorkflowSplitOn;
    $[8] = coachCallsSplitOn;
    $[9] = coachWeeklyGoalExpiryTreatment;
    $[10] = coachMemberUpdatedWeeklyGoalTreatment;
    $[11] = coachVideoVisitTreatment;
    $[12] = t1;
  } else {
    t1 = $[12];
  }
  const updateCoachWorkflowTypes = t1;
  let t2;
  if ($[13] !== surgeryTrendSplitOn || $[14] !== careCoordinationWorkflowDashboardSplitOn || $[15] !== aqRedFlagsSplitOn || $[16] !== ptActionPlanReviewWorkflowSplitOn || $[17] !== firstWkEnsoCheckInSplitOn || $[18] !== ptMemberAlertReminderSplitOn || $[19] !== setupActionPlanTreatment || $[20] !== indicationUpdateTreatment) {
    t2 = () => {
      let ptWorkflowTypesList = [...defaultPtWorkflows];
      if (surgeryTrendSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.SurgeryTrend];
      }
      if (careCoordinationWorkflowDashboardSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.EtModification, PtWorkflowTypes.ClinicalEscalation, PtWorkflowTypes.MedicalEmergencyFollowUp];
      }
      if (aqRedFlagsSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.AqRedFlags];
      }
      if (ptActionPlanReviewWorkflowSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.ActionPlanReview];
      }
      if (firstWkEnsoCheckInSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.FirstWeekEnsoCheckIn];
      }
      if (ptMemberAlertReminderSplitOn) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.PtMemberAlertReminder];
      }
      if (setupActionPlanTreatment) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.SetupActionPlan];
      }
      if (indicationUpdateTreatment) {
        ptWorkflowTypesList = [...ptWorkflowTypesList, PtWorkflowTypes.IndicationUpdate];
      }
      setWorkflowTypesToCount((ptWorkflowTypesList as T[]));
    };
    $[13] = surgeryTrendSplitOn;
    $[14] = careCoordinationWorkflowDashboardSplitOn;
    $[15] = aqRedFlagsSplitOn;
    $[16] = ptActionPlanReviewWorkflowSplitOn;
    $[17] = firstWkEnsoCheckInSplitOn;
    $[18] = ptMemberAlertReminderSplitOn;
    $[19] = setupActionPlanTreatment;
    $[20] = indicationUpdateTreatment;
    $[21] = t2;
  } else {
    t2 = $[21];
  }
  const updatePtWorkflowTypes = t2;
  let t3;
  if ($[22] === Symbol.for("react.memo_cache_sentinel")) {
    t3 = () => {
      const osWorkflowTypesList = [OsWorkflowTypes.UnreadMessages, OsWorkflowTypes.MemberAlertReminder, OsWorkflowTypes.ReEngager, OsWorkflowTypes.OutreachNeeded, OsWorkflowTypes.Nuto, OsWorkflowTypes.Ruto];
      setWorkflowTypesToCount(((osWorkflowTypesList as unknown) as T[]));
    };
    $[22] = t3;
  } else {
    t3 = $[22];
  }
  const updateOsWorkflowTypes = t3;
  const roles = hingeHealthAuthState?.accessToken?.claims.roles;
  let t4;
  let t5;
  if ($[23] !== roles) {
    t5 = roles?.includes(OktaRole.PhysicalTherapist);
    $[23] = roles;
    $[24] = t5;
  } else {
    t5 = $[24];
  }
  t4 = t5;
  const isPhysicalTherapist = t4;
  let t6;
  let t7;
  if ($[25] !== roles) {
    t7 = roles?.includes(OktaRole.OnboardingSpecialist);
    $[25] = roles;
    $[26] = t7;
  } else {
    t7 = $[26];
  }
  t6 = t7;
  const isOnboardingSpecialist = t6;
  let t8;
  let t9;
  if ($[27] !== isPhysicalTherapist || $[28] !== updatePtWorkflowTypes || $[29] !== isOnboardingSpecialist || $[30] !== updateCoachWorkflowTypes) {
    t8 = () => {
      if (isPhysicalTherapist) {
        updatePtWorkflowTypes();
      } else {
        if (isOnboardingSpecialist) {
          updateOsWorkflowTypes();
        } else {
          updateCoachWorkflowTypes();
        }
      }
    };
    t9 = [updateCoachWorkflowTypes, updatePtWorkflowTypes, isPhysicalTherapist, isOnboardingSpecialist, updateOsWorkflowTypes];
    $[27] = isPhysicalTherapist;
    $[28] = updatePtWorkflowTypes;
    $[29] = isOnboardingSpecialist;
    $[30] = updateCoachWorkflowTypes;
    $[31] = t8;
    $[32] = t9;
  } else {
    t8 = $[31];
    t9 = $[32];
  }
  useEffect(t8, t9);
  return workflowTypesToCount;
};